import React from "react"
import "./styles.scss"
import { Link } from "gatsby"

const navBar = () => (
  <div className="navbar-container">
    <div className="logo">
      <Link to="/">
        <span className="text-sm-dark" style={{ textDecoration: "none" }}>
          Dott. Stefano Maffina
        </span>
      </Link>
    </div>
    <div className="menu ">
      <div className="menu-desktop">
        <Link to="/about" activeClassName="menu-desktop__active">
          <span>Chi sono</span>
        </Link>
        <Link to="/work" activeClassName="menu-desktop__active">
          <span>Di cosa mi occupo? </span>
        </Link>
        <Link to="/method" activeClassName="menu-desktop__active">
          <span>Il mio approccio </span>
        </Link>
        <Link to="/blog" activeClassName="menu-desktop__active">
          <span>Blog </span>
        </Link>
        <Link to="/contact" activeClassName="menu-desktop__active">
          <span>Contattami </span>
        </Link>
      </div>
      <div className="menu-mobile">
        <Link to="/contact">
          <span className="text-sm-dark">Contattami</span>
        </Link>
      </div>
    </div>
  </div>
)

export default navBar
