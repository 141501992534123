import React from "react"
import "./layout.css"
import "../styles/main.scss"
import "../styles/animations.scss"
import NavBar from "../components/navBar"

const Layout = ({ children }) => {
  return (
    <div>
      <main>
        {children}
        <div className="line-left" />
        <div className="line-right" />
      </main>
    </div>
  )
}

export default Layout
